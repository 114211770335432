<template>
  <div class="msg-row">
    <div class="date">{{ msg.updateTime }}</div>
    <div v-if="msg.fromAccount == 'administrator'" class="tac c999">
      {{ getText(msg.timTextElem && msg.timTextElem.text) }}
    </div>
    <div
      class="content"
      v-else
      :class="msg.fromAccount == userID ? 'right-content' : '' "
    >
      <van-image
        class="avatar"
        radius="4"
        width="40"
        height="40"
        :src="msg.fromAccount == userID ? patientAvatar: doctorAvatar"
      ></van-image>
      <div
        v-if="msg.msgType == 'TIMCustomElem'"
        class="msg href-msg"
        @click="hrefMsgClick(msg)"
      >
        <div class="href-header">
          <div class="href-title">{{ msg.timCustomElem && msg.timCustomElem.data.split('^')[1] || '-' }}</div>
          <van-icon
            name="arrow"
            class="href-arrow"
          ></van-icon>
        </div>
        <div class="href-content">
          {{ msg.timCustomElem && msg.timCustomElem.desc }}
        </div>
      </div>
      <div
        class="msg"
        v-else
      >
        <span v-html="getText(msg.timTextElem && msg.timTextElem.text)"></span>
        <div class="arrow"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  name: 'msgRow',
  props: {
    msg: {
      type: Object,
      default: () => ({}),
    },
    patientAvatar: String,
    doctorAvatar: String,
    userID: String,
    businessId: String,
  },
  methods: {
    getText(text) {
      if (text && text.includes('^')) return text.split('^')[1];
      return text;
    },
    hrefMsgClick(msg) {
      if (msg.timCustomElem.data.indexOf('^') === -1) {
        return;
      }
      this.$get(`/api/blade-consult/recipe/getRecipe/${this.businessId}`)
        .then(({ data }) => {
          const status = data.recipeStatus;// 1，编辑中 2待审核 3驳回 4审核通过 5作废
          if (status === 2) {
            Dialog.alert({
              title: '提示',
              message: '处方药品加速审核中，审核通过即可支付😊，可至“个人中心”-“订单中心”-“药品订单”中查看',
            });
          } else if (status === 3) {
            Dialog.alert({
              title: '提示',
              message: '该处方审核未通过',
            });
          } else if (status === 4) {
            window.location.href = msg.timCustomElem.ext;
          } else if (status === 5) {
            Dialog.alert({
              title: '提示',
              message: '该处方已作废',
            });
          }
        });
    },
  }
}
</script>

<style lang="less" scoped>
.msg-row {
  margin-bottom: 12px;
  .date {
    text-align: center;
    color: #999;
    margin-bottom: 8px;
  }

  .href-msg {
    .href-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dashed #999;
      padding-bottom: 6px;
      margin-bottom: 6px;

      .href-title {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      & .href-arrow {
        flex-shrink: 0;
      }
    }
  }
  .content {
    display: flex;
    padding-right: 40px;
    align-items: flex-start;
    .avatar {
      flex-shrink: 0;
    }
    .msg {
      margin: 0 10px;
      background: white;
      padding: 10px;
      border-radius: 4px;
      line-height: 20px;
      min-height: 40px;
      position: relative;
      .arrow {
        top: 13px;
        position: absolute;
        left: -12px;
        border: 6px solid #ffffff00;
        border-right: 6px solid white;
      }
    }
    &.right-content {
      flex-direction: row-reverse;
      padding-left: 40px;
      padding-right: 0px;
      .msg {
        background-color: #95ec69;
        .arrow {
          border: 6px solid #ffffff00;
          border-left: 6px solid #95ec69;
          right: -12px;
          left: auto;
        }
      }
    }
  }
}
</style>
