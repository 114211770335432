<template>
  <div class="chat">
    <c-card class="page-top flex-between-center">
      <div class="flex-start-center">
        <div class="mr12 fs0">
          <van-image :src="userInfo.cardAvatar" round width="60" height="60"></van-image>
        </div>
        <div>
          <p class="mb12 dark fw500">
            <span class="mr6">{{ userInfo.cardName }}</span>
            <span class="mr6">{{ userInfo.sexName }}</span>
            <span>{{ userInfo.age }}</span>
          </p>
          <p class="info">24小时内可回复，超时自动结束</p>
        </div>
      </div>
      <div>
        <van-button type="danger" style="width: 65px" plain @click="endConsult">结束咨询</van-button>
      </div>
    </c-card>

    <!-- 聊天内容 -->
    <div ref="chatContent" class="msg-list">
      <van-pull-refresh v-model="isLoading" class="msg-list-bg pd12" :disabled="isFinished" @refresh="onRefresh">
        <msg-row
          v-for="msg in msgList"
          :key="msg.id"
          :businessId="businessId"
          :msg="msg"
          :doctorAvatar="doctorAvatar"
          :patientAvatar="patientAvatar"
          :userID="userID"
        ></msg-row>
      </van-pull-refresh>
    </div>

    <!-- 输入框 -->
    <div class="input-wrapper">
      <van-search
        v-model="content"
        left-icon=" "
        show-action
        placeholder="请输入聊天内容"
        maxlength="500"
        @search="onSend"
      >
        <template #action>
          <div class="send-btn" @click="onSend">发送</div>
        </template>
      </van-search>
    </div>

    <!-- 按钮 -->
    <div class="fixed-btns">
      <div v-if="userInfo.diagnosisStatus !== 2" class="mb12">
        <van-button type="danger" size="small" plain round @click="toDiagnosisPage">下诊断</van-button>
      </div>
      <div class="mb12">
        <van-button type="info" size="small" plain round @click="toPatientPage">查看病历</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
import dayjs from 'dayjs';
import cCard from '@/components/cCard.vue';
import website from '@/config/website';
import MsgRow from './components/msg-row.vue';

export default {
  components: { cCard, MsgRow },
  name: 'chat',
  data() {
    return {
      businessId: this.$route.query.businessId,
      userInfo: {},
      content: '',
      msgList: [],
      pageInfo: {
        current: 1,
        size: 10,
      },
      isLoading: false,
      doctorAvatar: '',
      patientAvatar: '',
      userID: '',
      isFinished: false,
    };
  },
  created() {
    const options = {
      SDKAppID: website.chatSDKAppID,
    };
    this.tim = TIM.create(options);
    this.tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });

    this.loginByIm();
    // 获取用户的基本信息
    this.getUserInfo();
  },
  mounted() {
    // 获取聊天记录
    this.getMsgList();
  },
  beforeDestroy() {
    this.tim.destroy();
  },
  methods: {
    toPatientPage() {
      this.$router.push({
        path: '/yisheng/patient',
        query: {
          businessId: this.businessId,
          currentTab: '2',
        },
      });
    },
    toDiagnosisPage() {
      this.$router.push({
        path: '/yisheng/diagnosis',
        query: {
          businessId: this.businessId,
        },
      });
    },
    onSend() {
      if (!this.content) {
        this.$toast('请输入聊天内容');
        return;
      }
      this.$post(`/api/blade-consult/tencentIm/sendMsg`, {
        businessId: this.businessId,
        msgType: TIM.TYPES.MSG_TEXT,
        timTextElem: {
          text: this.content,
        },
      }).then(() => {
        this.msgList.push({
          updateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          msgType: TIM.TYPES.MSG_TEXT,
          timTextElem: {
            text: this.content,
          },
          fromAccount: this.userID,
        });
        this.content = '';

        this.$nextTick(() => {
          const msgBg = this.$refs.chatContent; // 获取对象
          msgBg.scrollTop = msgBg.scrollHeight; // 滚动高度
        });
      });
    },
    // 下拉刷新
    onRefresh() {
      const params = {
        ...this.pageInfo,
        businessId: this.businessId,
        // msgKey: this.msgList[0]?.msgKey,
      };
      this.isLoading = true;
      this.$post(`/api/blade-consult/tencentIm/page`, params)
        .then(({ data }) => {
          const msgList = data?.records || [];
          this.msgList = ([...msgList.reverse()]).concat(this.msgList);
          this.isFinished = data?.total <= this.msgList.length;
          this.$nextTick(() => {
            const msgBg = this.$refs.chatContent; // 获取对象
            msgBg.scrollTop = 0; // 滚动高度
          });
          if (!this.isFinished) this.pageInfo.current += 1;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loginByIm() {
      this.$get(`/api/blade-consult/tencentIm/login/${this.businessId}`)
        .then(({ data }) => {
          if (data.fromAccountPic) {
            this.doctorAvatar = data.fromAccountPic;
          }
          if (data.toAccountPic) {
            this.patientAvatar = data.toAccountPic;
          }
          this.userID = data.userId;
          this.initIM({ userID: data.userId, userSig: data.userSig });
        });
    },
    initIM(payload) {
      const { tim } = this;

      tim.on(TIM.EVENT.MESSAGE_RECEIVED, (event) => {
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        // event.name - TIM.EVENT.MESSAGE_RECEIVED
        // event.data - 存储 Message 对象的数组 - [Message]
        const tempMsg = event.data[0];
        if (tempMsg.type === 'TIMCustomElem') {
          return;
        }
        let msgText = tempMsg.payload.text;
        if (msgText.indexOf('2^') > -1 || msgText.indexOf('3^') > -1) {
          [, msgText] = msgText.split('^');
        }
        const msg = {
          updateTime: dayjs(tempMsg.time * 1000).format('YYYY-MM-DD HH:mm:ss'),
          fromAccount: tempMsg.from,
          toAccount: tempMsg.to,
          msgType: tempMsg.type,
          timTextElem: { text: msgText },
        };
        this.msgList.splice(this.msgList.length, 0, msg);
        this.$nextTick(() => {
          const msgBg = this.$refs.chatContent; // 获取对象
          msgBg.scrollTop = msgBg.scrollHeight; // 滚动高度
        });
      });
      // 开始登录
      tim.login(payload);
    },
    // 获取消息列表
    getMsgList() {
      const params = {
        ...this.pageInfo,
        businessId: this.businessId,
        // msgKey: this.msgList[0]?.msgKey,
      };
      this.isLoading = true;
      this.$post(`/api/blade-consult/tencentIm/page`, params)
        .then(({ data }) => {
          const msgList = data?.records || [];
          this.msgList = ([...msgList.reverse()]).concat(this.msgList);
          this.isFinished = data?.total <= this.msgList.length;
          this.$nextTick(() => {
            const msgBg = this.$refs.chatContent; // 获取对象
            if (msgBg.scrollHeight <= msgBg.clientHeight) {
              return;
            }
            msgBg.scrollTop = msgBg.scrollHeight; // 滚动高度
          });
          if (!this.isFinished) this.pageInfo.current += 1;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 获取用户的基本信息
    getUserInfo() {
      this.$get(`/api/blade-consult/doctorWork/acceptsPatient/${this.businessId}`)
        .then(({ data }) => {
          this.userInfo = data || {};
        });
    },
    // 结束咨询
    endConsult() {
      this.$dialog.confirm({
        message: '结束后患者将无法继续咨询?',
        title: '提示',
        type: 'warning',
      }).then(() => {
        this.$get(`/api/blade-consult/doctorWork/finishAcceptsPatient/${this.businessId}`)
          .then(() => {
            this.$toast('已结束');
            setTimeout(() => {
              this.$router.back();
            }, 1000);
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.chat {
  .input-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    .send-btn {
      background-color: #07c160;
      border: 1px solid #07c160;
      border-radius: 6px;
      color: #fff;
      text-align: center;
      width: 40px;
      font-size: 12px;
    }
  }
  .msg-list {
    margin: -12px;
    height: calc(100vh - 84px - 54px);
    overflow: auto;
  }

  .fixed-btns {
    position: fixed;
    right: 12px;
    bottom: 60px;
    text-align: center;
  }
}
</style>
